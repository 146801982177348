





















































import { Component, Prop, Vue } from 'vue-property-decorator';
  
const avatars_9277 = require("@/assets/avatars/9277.jpg");
const avatars_scott = require("@/assets/avatars/scott.jpg");
const avatars_hahaschool = require("@/assets/avatars/hahaschool.jpg");
const avatars_eddie = require("@/assets/avatars/Eddie.jpg");
const avatars_by = require("@/assets/avatars/by.jpg");

@Component
export default class Team extends Vue {
  @Prop() private msg!: string;
  data() {
    return {
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      avatars_9277,
      avatars_scott,
      avatars_eddie,
      avatars_hahaschool,
      avatars_by
    }
  }
}
