<template>
  <div class="home">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="6">
            <el-image alt="255 DAO Logo" :src="require('@/assets/logo.jpg')" :fit="fit" class="logo">
            </el-image>
        </el-col>
      </el-row>


    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="16">
    <HelloWorld msg="MAXIMIZE your staking profit"/>
      </el-col>
    </el-row>

    <el-row type="flex" class="row-bg" justify="center" :gutter="0">
      <!-- twitter -->
      <el-col :span="4">
      <a class="nes-icon twitter is-large" href="https://twitter.com/255dao"></a>
      </el-col>
      <el-col :span="4">
      <a class="nes-icon telegram is-large" href="https://t.me/dao255"></a>
      </el-col>
      <el-col :span="4">
      <a class="nes-icon discord is-large" href="https://discord.gg/255DAO"></a>
      </el-col>
    </el-row>

  </div>

</template>

<style>
.logo{
  margin-bottom: 10px;
}
.telegram::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1px 1px 0 rgb(255, 255, 255),
  2px 1px 0 rgb(84, 161, 225),
  3px 1px 0 rgb(84, 161, 225),
  4px 1px 0 rgb(84, 161, 225),
  5px 1px 0 rgb(84, 161, 225),
  6px 1px 0 rgb(84, 161, 225),
  7px 1px 0 rgb(84, 161, 225),
  8px 1px 0 rgb(84, 161, 225),
  9px 1px 0 rgb(84, 161, 225),
  10px 1px 0 rgb(84, 161, 225),
  11px 1px 0 rgb(84, 161, 225),
  12px 1px 0 rgb(84, 161, 225),
  13px 1px 0 rgb(84, 161, 225),
  14px 1px 0 rgb(84, 161, 225),
  15px 1px 0 rgb(84, 161, 225),
  16px 1px 0 rgb(255, 255, 255),
  1px 2px 0 rgb(84, 161, 225),
  2px 2px 0 rgb(84, 161, 225),
  3px 2px 0 rgb(84, 161, 225),
  4px 2px 0 rgb(84, 161, 225),
  5px 2px 0 rgb(84, 161, 225),
  6px 2px 0 rgb(84, 161, 225),
  7px 2px 0 rgb(84, 161, 225),
  8px 2px 0 rgb(84, 161, 225),
  9px 2px 0 rgb(84, 161, 225),
  10px 2px 0 rgb(84, 161, 225),
  11px 2px 0 rgb(84, 161, 225),
  12px 2px 0 rgb(84, 161, 225),
  13px 2px 0 rgb(84, 161, 225),
  14px 2px 0 rgb(84, 161, 225),
  15px 2px 0 rgb(84, 161, 225),
  16px 2px 0 rgb(84, 161, 225),
  1px 3px 0 rgb(84, 161, 225),
  2px 3px 0 rgb(84, 161, 225),
  3px 3px 0 rgb(84, 161, 225),
  4px 3px 0 rgb(84, 161, 225),
  5px 3px 0 rgb(84, 161, 225),
  6px 3px 0 rgb(84, 161, 225),
  7px 3px 0 rgb(84, 161, 225),
  8px 3px 0 rgb(84, 161, 225),
  9px 3px 0 rgb(84, 161, 225),
  10px 3px 0 rgb(84, 161, 225),
  11px 3px 0 rgb(84, 161, 225),
  12px 3px 0 rgb(84, 161, 225),
  13px 3px 0 rgb(84, 161, 225),
  14px 3px 0 rgb(84, 161, 225),
  15px 3px 0 rgb(84, 161, 225),
  16px 3px 0 rgb(84, 161, 225),
  1px 4px 0 rgb(84, 161, 225),
  2px 4px 0 rgb(84, 161, 225),
  3px 4px 0 rgb(84, 161, 225),
  4px 4px 0 rgb(84, 161, 225),
  5px 4px 0 rgb(84, 161, 225),
  6px 4px 0 rgb(84, 161, 225),
  7px 4px 0 rgb(84, 161, 225),
  8px 4px 0 rgb(84, 161, 225),
  9px 4px 0 rgb(84, 161, 225),
  10px 4px 0 rgb(84, 161, 225),
  11px 4px 0 rgb(84, 161, 225),
  12px 4px 0 rgb(84, 161, 225),
  13px 4px 0 rgb(84, 161, 225),
  14px 4px 0 rgb(84, 161, 225),
  15px 4px 0 rgb(84, 161, 225),
  16px 4px 0 rgb(84, 161, 225),
  1px 5px 0 rgb(84, 161, 225),
  2px 5px 0 rgb(84, 161, 225),
  3px 5px 0 rgb(84, 161, 225),
  4px 5px 0 rgb(84, 161, 225),
  5px 5px 0 rgb(84, 161, 225),
  6px 5px 0 rgb(84, 161, 225),
  7px 5px 0 rgb(84, 161, 225),
  8px 5px 0 rgb(84, 161, 225),
  9px 5px 0 rgb(84, 161, 225),
  10px 5px 0 rgb(84, 161, 225),
  11px 5px 0 rgb(255, 255, 255),
  12px 5px 0 rgb(255, 255, 255),
  13px 5px 0 rgb(84, 161, 225),
  14px 5px 0 rgb(84, 161, 225),
  15px 5px 0 rgb(84, 161, 225),
  16px 5px 0 rgb(84, 161, 225),
  1px 6px 0 rgb(84, 161, 225),
  2px 6px 0 rgb(84, 161, 225),
  3px 6px 0 rgb(84, 161, 225),
  4px 6px 0 rgb(84, 161, 225),
  5px 6px 0 rgb(84, 161, 225),
  6px 6px 0 rgb(84, 161, 225),
  7px 6px 0 rgb(84, 161, 225),
  8px 6px 0 rgb(84, 161, 225),
  9px 6px 0 rgb(255, 255, 255),
  10px 6px 0 rgb(255, 255, 255),
  11px 6px 0 rgb(255, 255, 255),
  12px 6px 0 rgb(255, 255, 255),
  13px 6px 0 rgb(84, 161, 225),
  14px 6px 0 rgb(84, 161, 225),
  15px 6px 0 rgb(84, 161, 225),
  16px 6px 0 rgb(84, 161, 225),
  1px 7px 0 rgb(84, 161, 225),
  2px 7px 0 rgb(84, 161, 225),
  3px 7px 0 rgb(84, 161, 225),
  4px 7px 0 rgb(84, 161, 225),
  5px 7px 0 rgb(84, 161, 225),
  6px 7px 0 rgb(84, 161, 225),
  7px 7px 0 rgb(255, 255, 255),
  8px 7px 0 rgb(255, 255, 255),
  9px 7px 0 rgb(255, 255, 255),
  10px 7px 0 rgb(84, 161, 225),
  11px 7px 0 rgb(255, 255, 255),
  12px 7px 0 rgb(255, 255, 255),
  13px 7px 0 rgb(84, 161, 225),
  14px 7px 0 rgb(84, 161, 225),
  15px 7px 0 rgb(84, 161, 225),
  16px 7px 0 rgb(84, 161, 225),
  1px 8px 0 rgb(84, 161, 225),
  2px 8px 0 rgb(84, 161, 225),
  3px 8px 0 rgb(84, 161, 225),
  4px 8px 0 rgb(84, 161, 225),
  5px 8px 0 rgb(255, 255, 255),
  6px 8px 0 rgb(255, 255, 255),
  7px 8px 0 rgb(255, 255, 255),
  8px 8px 0 rgb(255, 255, 255),
  9px 8px 0 rgb(84, 161, 225),
  10px 8px 0 rgb(255, 255, 255),
  11px 8px 0 rgb(255, 255, 255),
  12px 8px 0 rgb(84, 161, 225),
  13px 8px 0 rgb(84, 161, 225),
  14px 8px 0 rgb(84, 161, 225),
  15px 8px 0 rgb(84, 161, 225),
  16px 8px 0 rgb(84, 161, 225),
  1px 9px 0 rgb(84, 161, 225),
  2px 9px 0 rgb(84, 161, 225),
  3px 9px 0 rgb(84, 161, 225),
  4px 9px 0 rgb(255, 255, 255),
  5px 9px 0 rgb(255, 255, 255),
  6px 9px 0 rgb(255, 255, 255),
  7px 9px 0 rgb(255, 255, 255),
  8px 9px 0 rgb(84, 161, 225),
  9px 9px 0 rgb(255, 255, 255),
  10px 9px 0 rgb(255, 255, 255),
  11px 9px 0 rgb(255, 255, 255),
  12px 9px 0 rgb(84, 161, 225),
  13px 9px 0 rgb(84, 161, 225),
  14px 9px 0 rgb(84, 161, 225),
  15px 9px 0 rgb(84, 161, 225),
  16px 9px 0 rgb(84, 161, 225),
  1px 10px 0 rgb(84, 161, 225),
  2px 10px 0 rgb(84, 161, 225),
  3px 10px 0 rgb(84, 161, 225),
  4px 10px 0 rgb(84, 161, 225),
  5px 10px 0 rgb(84, 161, 225),
  6px 10px 0 rgb(255, 255, 255),
  7px 10px 0 rgb(84, 161, 225),
  8px 10px 0 rgb(255, 255, 255),
  9px 10px 0 rgb(255, 255, 255),
  10px 10px 0 rgb(255, 255, 255),
  11px 10px 0 rgb(255, 255, 255),
  12px 10px 0 rgb(84, 161, 225),
  13px 10px 0 rgb(84, 161, 225),
  14px 10px 0 rgb(84, 161, 225),
  15px 10px 0 rgb(84, 161, 225),
  16px 10px 0 rgb(84, 161, 225),
  1px 11px 0 rgb(84, 161, 225),
  2px 11px 0 rgb(84, 161, 225),
  3px 11px 0 rgb(84, 161, 225),
  4px 11px 0 rgb(84, 161, 225),
  5px 11px 0 rgb(84, 161, 225),
  6px 11px 0 rgb(84, 161, 225),
  7px 11px 0 rgb(84, 161, 225),
  8px 11px 0 rgb(255, 255, 255),
  9px 11px 0 rgb(255, 255, 255),
  10px 11px 0 rgb(255, 255, 255),
  11px 11px 0 rgb(255, 255, 255),
  12px 11px 0 rgb(84, 161, 225),
  13px 11px 0 rgb(84, 161, 225),
  14px 11px 0 rgb(84, 161, 225),
  15px 11px 0 rgb(84, 161, 225),
  16px 11px 0 rgb(84, 161, 225),
  1px 12px 0 rgb(84, 161, 225),
  2px 12px 0 rgb(84, 161, 225),
  3px 12px 0 rgb(84, 161, 225),
  4px 12px 0 rgb(84, 161, 225),
  5px 12px 0 rgb(84, 161, 225),
  6px 12px 0 rgb(84, 161, 225),
  7px 12px 0 rgb(84, 161, 225),
  8px 12px 0 rgb(84, 161, 225),
  9px 12px 0 rgb(255, 255, 255),
  10px 12px 0 rgb(255, 255, 255),
  11px 12px 0 rgb(84, 161, 225),
  12px 12px 0 rgb(84, 161, 225),
  13px 12px 0 rgb(84, 161, 225),
  14px 12px 0 rgb(84, 161, 225),
  15px 12px 0 rgb(84, 161, 225),
  16px 12px 0 rgb(84, 161, 225),
  1px 13px 0 rgb(84, 161, 225),
  2px 13px 0 rgb(84, 161, 225),
  3px 13px 0 rgb(84, 161, 225),
  4px 13px 0 rgb(84, 161, 225),
  5px 13px 0 rgb(84, 161, 225),
  6px 13px 0 rgb(84, 161, 225),
  7px 13px 0 rgb(84, 161, 225),
  8px 13px 0 rgb(84, 161, 225),
  9px 13px 0 rgb(84, 161, 225),
  10px 13px 0 rgb(255, 255, 255),
  11px 13px 0 rgb(84, 161, 225),
  12px 13px 0 rgb(84, 161, 225),
  13px 13px 0 rgb(84, 161, 225),
  14px 13px 0 rgb(84, 161, 225),
  15px 13px 0 rgb(84, 161, 225),
  16px 13px 0 rgb(84, 161, 225),
  1px 14px 0 rgb(84, 161, 225),
  2px 14px 0 rgb(84, 161, 225),
  3px 14px 0 rgb(84, 161, 225),
  4px 14px 0 rgb(84, 161, 225),
  5px 14px 0 rgb(84, 161, 225),
  6px 14px 0 rgb(84, 161, 225),
  7px 14px 0 rgb(84, 161, 225),
  8px 14px 0 rgb(84, 161, 225),
  9px 14px 0 rgb(84, 161, 225),
  10px 14px 0 rgb(84, 161, 225),
  11px 14px 0 rgb(84, 161, 225),
  12px 14px 0 rgb(84, 161, 225),
  13px 14px 0 rgb(84, 161, 225),
  14px 14px 0 rgb(84, 161, 225),
  15px 14px 0 rgb(84, 161, 225),
  16px 14px 0 rgb(84, 161, 225),
  1px 15px 0 rgb(84, 161, 225),
  2px 15px 0 rgb(84, 161, 225),
  3px 15px 0 rgb(84, 161, 225),
  4px 15px 0 rgb(84, 161, 225),
  5px 15px 0 rgb(84, 161, 225),
  6px 15px 0 rgb(84, 161, 225),
  7px 15px 0 rgb(84, 161, 225),
  8px 15px 0 rgb(84, 161, 225),
  9px 15px 0 rgb(84, 161, 225),
  10px 15px 0 rgb(84, 161, 225),
  11px 15px 0 rgb(84, 161, 225),
  12px 15px 0 rgb(84, 161, 225),
  13px 15px 0 rgb(84, 161, 225),
  14px 15px 0 rgb(84, 161, 225),
  15px 15px 0 rgb(84, 161, 225),
  16px 15px 0 rgb(84, 161, 225),
  1px 16px 0 rgb(255, 255, 255),
  2px 16px 0 rgb(84, 161, 225),
  3px 16px 0 rgb(84, 161, 225),
  4px 16px 0 rgb(84, 161, 225),
  5px 16px 0 rgb(84, 161, 225),
  6px 16px 0 rgb(84, 161, 225),
  7px 16px 0 rgb(84, 161, 225),
  8px 16px 0 rgb(84, 161, 225),
  9px 16px 0 rgb(84, 161, 225),
  10px 16px 0 rgb(84, 161, 225),
  11px 16px 0 rgb(84, 161, 225),
  12px 16px 0 rgb(84, 161, 225),
  13px 16px 0 rgb(84, 161, 225),
  14px 16px 0 rgb(84, 161, 225),
  15px 16px 0 rgb(84, 161, 225),
  16px 16px 0 rgb(255, 255, 255);
}
.discord::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1px 1px 0 rgb(255, 255, 255),
  2px 1px 0 rgb(67, 78, 228),
  3px 1px 0 rgb(67, 78, 228),
  4px 1px 0 rgb(67, 78, 228),
  5px 1px 0 rgb(67, 78, 228),
  6px 1px 0 rgb(67, 78, 228),
  7px 1px 0 rgb(67, 78, 228),
  8px 1px 0 rgb(67, 78, 228),
  9px 1px 0 rgb(67, 78, 228),
  10px 1px 0 rgb(67, 78, 228),
  11px 1px 0 rgb(67, 78, 228),
  12px 1px 0 rgb(67, 78, 228),
  13px 1px 0 rgb(67, 78, 228),
  14px 1px 0 rgb(67, 78, 228),
  15px 1px 0 rgb(67, 78, 228),
  16px 1px 0 rgb(255, 255, 255),
  1px 2px 0 rgb(67, 78, 228),
  2px 2px 0 rgb(67, 78, 228),
  3px 2px 0 rgb(67, 78, 228),
  4px 2px 0 rgb(67, 78, 228),
  5px 2px 0 rgb(67, 78, 228),
  6px 2px 0 rgb(67, 78, 228),
  7px 2px 0 rgb(67, 78, 228),
  8px 2px 0 rgb(67, 78, 228),
  9px 2px 0 rgb(67, 78, 228),
  10px 2px 0 rgb(67, 78, 228),
  11px 2px 0 rgb(67, 78, 228),
  12px 2px 0 rgb(67, 78, 228),
  13px 2px 0 rgb(67, 78, 228),
  14px 2px 0 rgb(67, 78, 228),
  15px 2px 0 rgb(67, 78, 228),
  16px 2px 0 rgb(67, 78, 228),
  1px 3px 0 rgb(67, 78, 228),
  2px 3px 0 rgb(67, 78, 228),
  3px 3px 0 rgb(67, 78, 228),
  4px 3px 0 rgb(67, 78, 228),
  5px 3px 0 rgb(67, 78, 228),
  6px 3px 0 rgb(255, 255, 255),
  7px 3px 0 rgb(67, 78, 228),
  8px 3px 0 rgb(67, 78, 228),
  9px 3px 0 rgb(67, 78, 228),
  10px 3px 0 rgb(67, 78, 228),
  11px 3px 0 rgb(255, 255, 255),
  12px 3px 0 rgb(67, 78, 228),
  13px 3px 0 rgb(67, 78, 228),
  14px 3px 0 rgb(67, 78, 228),
  15px 3px 0 rgb(67, 78, 228),
  16px 3px 0 rgb(67, 78, 228),
  1px 4px 0 rgb(67, 78, 228),
  2px 4px 0 rgb(67, 78, 228),
  3px 4px 0 rgb(67, 78, 228),
  4px 4px 0 rgb(255, 255, 255),
  5px 4px 0 rgb(255, 255, 255),
  6px 4px 0 rgb(255, 255, 255),
  7px 4px 0 rgb(255, 255, 255),
  8px 4px 0 rgb(255, 255, 255),
  9px 4px 0 rgb(255, 255, 255),
  10px 4px 0 rgb(255, 255, 255),
  11px 4px 0 rgb(255, 255, 255),
  12px 4px 0 rgb(255, 255, 255),
  13px 4px 0 rgb(255, 255, 255),
  14px 4px 0 rgb(67, 78, 228),
  15px 4px 0 rgb(67, 78, 228),
  16px 4px 0 rgb(67, 78, 228),
  1px 5px 0 rgb(67, 78, 228),
  2px 5px 0 rgb(67, 78, 228),
  3px 5px 0 rgb(67, 78, 228),
  4px 5px 0 rgb(255, 255, 255),
  5px 5px 0 rgb(255, 255, 255),
  6px 5px 0 rgb(255, 255, 255),
  7px 5px 0 rgb(255, 255, 255),
  8px 5px 0 rgb(255, 255, 255),
  9px 5px 0 rgb(255, 255, 255),
  10px 5px 0 rgb(255, 255, 255),
  11px 5px 0 rgb(255, 255, 255),
  12px 5px 0 rgb(255, 255, 255),
  13px 5px 0 rgb(255, 255, 255),
  14px 5px 0 rgb(67, 78, 228),
  15px 5px 0 rgb(67, 78, 228),
  16px 5px 0 rgb(67, 78, 228),
  1px 6px 0 rgb(67, 78, 228),
  2px 6px 0 rgb(67, 78, 228),
  3px 6px 0 rgb(255, 255, 255),
  4px 6px 0 rgb(255, 255, 255),
  5px 6px 0 rgb(255, 255, 255),
  6px 6px 0 rgb(255, 255, 255),
  7px 6px 0 rgb(255, 255, 255),
  8px 6px 0 rgb(255, 255, 255),
  9px 6px 0 rgb(255, 255, 255),
  10px 6px 0 rgb(255, 255, 255),
  11px 6px 0 rgb(255, 255, 255),
  12px 6px 0 rgb(255, 255, 255),
  13px 6px 0 rgb(255, 255, 255),
  14px 6px 0 rgb(255, 255, 255),
  15px 6px 0 rgb(67, 78, 228),
  16px 6px 0 rgb(67, 78, 228),
  1px 7px 0 rgb(67, 78, 228),
  2px 7px 0 rgb(67, 78, 228),
  3px 7px 0 rgb(255, 255, 255),
  4px 7px 0 rgb(255, 255, 255),
  5px 7px 0 rgb(255, 255, 255),
  6px 7px 0 rgb(255, 255, 255),
  7px 7px 0 rgb(255, 255, 255),
  8px 7px 0 rgb(255, 255, 255),
  9px 7px 0 rgb(255, 255, 255),
  10px 7px 0 rgb(255, 255, 255),
  11px 7px 0 rgb(255, 255, 255),
  12px 7px 0 rgb(255, 255, 255),
  13px 7px 0 rgb(255, 255, 255),
  14px 7px 0 rgb(255, 255, 255),
  15px 7px 0 rgb(67, 78, 228),
  16px 7px 0 rgb(67, 78, 228),
  1px 8px 0 rgb(67, 78, 228),
  2px 8px 0 rgb(255, 255, 255),
  3px 8px 0 rgb(255, 255, 255),
  4px 8px 0 rgb(255, 255, 255),
  5px 8px 0 rgb(255, 255, 255),
  6px 8px 0 rgb(67, 78, 228),
  7px 8px 0 rgb(255, 255, 255),
  8px 8px 0 rgb(255, 255, 255),
  9px 8px 0 rgb(255, 255, 255),
  10px 8px 0 rgb(255, 255, 255),
  11px 8px 0 rgb(67, 78, 228),
  12px 8px 0 rgb(255, 255, 255),
  13px 8px 0 rgb(255, 255, 255),
  14px 8px 0 rgb(255, 255, 255),
  15px 8px 0 rgb(255, 255, 255),
  16px 8px 0 rgb(67, 78, 228),
  1px 9px 0 rgb(67, 78, 228),
  2px 9px 0 rgb(255, 255, 255),
  3px 9px 0 rgb(255, 255, 255),
  4px 9px 0 rgb(255, 255, 255),
  5px 9px 0 rgb(67, 78, 228),
  6px 9px 0 rgb(67, 78, 228),
  7px 9px 0 rgb(67, 78, 228),
  8px 9px 0 rgb(255, 255, 255),
  9px 9px 0 rgb(255, 255, 255),
  10px 9px 0 rgb(67, 78, 228),
  11px 9px 0 rgb(67, 78, 228),
  12px 9px 0 rgb(67, 78, 228),
  13px 9px 0 rgb(255, 255, 255),
  14px 9px 0 rgb(255, 255, 255),
  15px 9px 0 rgb(255, 255, 255),
  16px 9px 0 rgb(67, 78, 228),
  1px 10px 0 rgb(67, 78, 228),
  2px 10px 0 rgb(255, 255, 255),
  3px 10px 0 rgb(255, 255, 255),
  4px 10px 0 rgb(255, 255, 255),
  5px 10px 0 rgb(255, 255, 255),
  6px 10px 0 rgb(67, 78, 228),
  7px 10px 0 rgb(255, 255, 255),
  8px 10px 0 rgb(255, 255, 255),
  9px 10px 0 rgb(255, 255, 255),
  10px 10px 0 rgb(255, 255, 255),
  11px 10px 0 rgb(67, 78, 228),
  12px 10px 0 rgb(255, 255, 255),
  13px 10px 0 rgb(255, 255, 255),
  14px 10px 0 rgb(255, 255, 255),
  15px 10px 0 rgb(255, 255, 255),
  16px 10px 0 rgb(67, 78, 228),
  1px 11px 0 rgb(67, 78, 228),
  2px 11px 0 rgb(255, 255, 255),
  3px 11px 0 rgb(255, 255, 255),
  4px 11px 0 rgb(255, 255, 255),
  5px 11px 0 rgb(255, 255, 255),
  6px 11px 0 rgb(255, 255, 255),
  7px 11px 0 rgb(255, 255, 255),
  8px 11px 0 rgb(255, 255, 255),
  9px 11px 0 rgb(255, 255, 255),
  10px 11px 0 rgb(255, 255, 255),
  11px 11px 0 rgb(255, 255, 255),
  12px 11px 0 rgb(255, 255, 255),
  13px 11px 0 rgb(255, 255, 255),
  14px 11px 0 rgb(255, 255, 255),
  15px 11px 0 rgb(255, 255, 255),
  16px 11px 0 rgb(67, 78, 228),
  1px 12px 0 rgb(67, 78, 228),
  2px 12px 0 rgb(255, 255, 255),
  3px 12px 0 rgb(255, 255, 255),
  4px 12px 0 rgb(255, 255, 255),
  5px 12px 0 rgb(67, 78, 228),
  6px 12px 0 rgb(255, 255, 255),
  7px 12px 0 rgb(255, 255, 255),
  8px 12px 0 rgb(255, 255, 255),
  9px 12px 0 rgb(255, 255, 255),
  10px 12px 0 rgb(255, 255, 255),
  11px 12px 0 rgb(255, 255, 255),
  12px 12px 0 rgb(67, 78, 228),
  13px 12px 0 rgb(255, 255, 255),
  14px 12px 0 rgb(255, 255, 255),
  15px 12px 0 rgb(255, 255, 255),
  16px 12px 0 rgb(67, 78, 228),
  1px 13px 0 rgb(67, 78, 228),
  2px 13px 0 rgb(67, 78, 228),
  3px 13px 0 rgb(67, 78, 228),
  4px 13px 0 rgb(255, 255, 255),
  5px 13px 0 rgb(255, 255, 255),
  6px 13px 0 rgb(67, 78, 228),
  7px 13px 0 rgb(67, 78, 228),
  8px 13px 0 rgb(67, 78, 228),
  9px 13px 0 rgb(67, 78, 228),
  10px 13px 0 rgb(67, 78, 228),
  11px 13px 0 rgb(67, 78, 228),
  12px 13px 0 rgb(255, 255, 255),
  13px 13px 0 rgb(255, 255, 255),
  14px 13px 0 rgb(67, 78, 228),
  15px 13px 0 rgb(67, 78, 228),
  16px 13px 0 rgb(67, 78, 228),
  1px 14px 0 rgb(67, 78, 228),
  2px 14px 0 rgb(67, 78, 228),
  3px 14px 0 rgb(67, 78, 228),
  4px 14px 0 rgb(67, 78, 228),
  5px 14px 0 rgb(67, 78, 228),
  6px 14px 0 rgb(67, 78, 228),
  7px 14px 0 rgb(67, 78, 228),
  8px 14px 0 rgb(67, 78, 228),
  9px 14px 0 rgb(67, 78, 228),
  10px 14px 0 rgb(67, 78, 228),
  11px 14px 0 rgb(67, 78, 228),
  12px 14px 0 rgb(67, 78, 228),
  13px 14px 0 rgb(67, 78, 228),
  14px 14px 0 rgb(67, 78, 228),
  15px 14px 0 rgb(67, 78, 228),
  16px 14px 0 rgb(67, 78, 228),
  1px 15px 0 rgb(67, 78, 228),
  2px 15px 0 rgb(67, 78, 228),
  3px 15px 0 rgb(67, 78, 228),
  4px 15px 0 rgb(67, 78, 228),
  5px 15px 0 rgb(67, 78, 228),
  6px 15px 0 rgb(67, 78, 228),
  7px 15px 0 rgb(67, 78, 228),
  8px 15px 0 rgb(67, 78, 228),
  9px 15px 0 rgb(67, 78, 228),
  10px 15px 0 rgb(67, 78, 228),
  11px 15px 0 rgb(67, 78, 228),
  12px 15px 0 rgb(67, 78, 228),
  13px 15px 0 rgb(67, 78, 228),
  14px 15px 0 rgb(67, 78, 228),
  15px 15px 0 rgb(67, 78, 228),
  16px 15px 0 rgb(67, 78, 228),
  1px 16px 0 rgb(255, 255, 255),
  2px 16px 0 rgb(67, 78, 228),
  3px 16px 0 rgb(67, 78, 228),
  4px 16px 0 rgb(67, 78, 228),
  5px 16px 0 rgb(67, 78, 228),
  6px 16px 0 rgb(67, 78, 228),
  7px 16px 0 rgb(67, 78, 228),
  8px 16px 0 rgb(67, 78, 228),
  9px 16px 0 rgb(67, 78, 228),
  10px 16px 0 rgb(67, 78, 228),
  11px 16px 0 rgb(67, 78, 228),
  12px 16px 0 rgb(67, 78, 228),
  13px 16px 0 rgb(67, 78, 228),
  14px 16px 0 rgb(67, 78, 228),
  15px 16px 0 rgb(67, 78, 228),
  16px 16px 0 rgb(255, 255, 255);
}

</style>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
